import { useTheme } from "next-themes"
import React from "react"
import FlatCard from "@common/cards/flatCard"

export default function SampleReports() {
  const { theme } = useTheme()
  const isDarkMode = theme == "dark"
  const reports = [
    {
      imagePathLight: "/images/tri/keyboard_lp.svg",
      imagePathDark: "/images/tri/keyboard_lp_dark.svg",
      reportName: "[SPAM] Redragon K552 Mechanical Gaming Keyboard",
      reportLink:
        "/us/p/Redragon_K552_Mechanical_Gaming_Keyboard_RGB_LED_Rainbow_Backlit_Wired_Keyboard_with_Red_Switches_fo/AZ-US_B016MAK38U",
    },
    {
      imagePathLight: "/images/tri/earpods_lp.svg",
      imagePathDark: "/images/tri/earpods_lp_dark.svg",
      reportName: "Apple AirPods with Charging Case",
      reportLink:
        "/us/p/Apple_AirPods_with_Charging_Case_Previous_Model/AZ-US_B01MQWUXZS",
    },
    {
      imagePathLight: "/images/tri/smartwatch_lp.svg",
      imagePathDark: "/images/tri/smartwatch_lp_dark.svg",
      reportName: "Fitbit Charge 2 Heart Rate",
      reportLink:
        "/us/p/Fitbit_Charge_2_Heart_Rate_Fitness_Wristband_Black_Large_US_Version_1_Count/AZ-US_B01K9S260E",
    },
  ]

  return (
    <div className="flex flex-wrap justify-center gap-5 sm:gap-1 lg:gap-5  ">
      {reports.map(
        ({ imagePathLight, imagePathDark, reportName, reportLink }, index) => (
          <FlatCard
            key={index}
            id={reportName}
            cardImage={isDarkMode ? imagePathDark : imagePathLight}
            cardName={reportName}
            cardDescription={"Sample report -> "}
            cardUrl={reportLink}
            wrapLink={true}
            newTab={true}
          />
        )
      )}
    </div>
  )
}
