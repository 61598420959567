import React from "react"
import TriLandingUnit from "../tri/triLandingUnit"
import ImageTabs from "@common/landing/imageTabs"
import { Header } from "@common/header/headerUnit"
import LogoGridUnit from "@common/landing/logoGridUnit"
import CtaUnit from "@common/landing/ctaUnit"
import FeaturesUnit from "@common/landing/featuresUnit"
import FeatureWithSideImage from "@common/landing/featureWithSideImage"
import StatsUnit from "@common/landing/statsUnit"
import { useTheme } from "next-themes"
import { useRouter } from "next/router"
import { Container8xl } from "@common/container"
//import TestimonyUnit from "@common/landing/testimonyUnit"

const tabData = [
  {
    imgPath: "/images/screenshots/tri/light/category-top-reports.png",
    imgPathDark: "/images/screenshots/tri/dark/category-top-reports.png",
    label: "Category Top Products",
  },
  {
    imgPath: "/images/screenshots/tri/light/review-insights.png",
    imgPathDark: "/images/screenshots/tri/dark/review-insights.png",
    label: "Review Insights",
  },
  {
    imgPath: "/images/screenshots/tri/light/brand-comparison.png",
    imgPathDark: "/images/screenshots/tri/dark/brand-comparison.png",
    label: "Brand Comparison",
  },
]

const customerIcons = [
  { imgPath: "/images/customers/traeger.png" },
  { imgPath: "/images/customers/google.png" },
  { imgPath: "/images/customers/xiomi.png" },
  { imgPath: "/images/customers/anker.png" },
  { imgPath: "/images/customers/soundcore.png" },
  { imgPath: "/images/customers/eufy.png" },
  { imgPath: "/images/customers/moonshot.png" },
  { imgPath: "/images/customers/hellofresh.png" },
]

const channelIcons = [
  { imgPath: "/images/channels/ace.png" },
  { imgPath: "/images/channels/ulta.png" },
  { imgPath: "/images/channels/amazon-full.png" },
  { imgPath: "/images/channels/amazon-uk-full.png" },
  { imgPath: "/images/channels/amazon-in-full.png" },
  { imgPath: "/images/channels/amazon-de-full.png" },
  { imgPath: "/images/channels/amazon-es-full.png" },
  { imgPath: "/images/channels/bb.png" },
  { imgPath: "/images/channels/wallmart.png" },
  { imgPath: "/images/channels/target.png" },
  { imgPath: "/images/channels/hd.png" },
  { imgPath: "/images/channels/jd.png" },
  { imgPath: "/images/channels/ml.png" },
  { imgPath: "/images/channels/costco.png" },
]

const featuresData = [
  {
    header: "Product Team",
    subheader:
      "Identify trending opportunities. Track products and prioritize features based on data.",
  },
  {
    header: "Customer Success",
    subheader:
      "Discover trending issues early. Drill down to the root cause and resolve problems faster.",
  },
  {
    header: "Insights Team",
    subheader:
      "Skip over manual tagging and analysis processes. Get right to higher order insights.",
  },
  {
    header: "R&D",
    subheader:
      "Uncover industry and category specific nuances. Predict future trends and opportunities.",
  },
  {
    header: "Marketing",
    subheader:
      "Deeply understand your users and personas. Craft marketing campaigns with the perfect appeal.",
  },
  {
    header: "Merchandising",
    subheader:
      "Use language and phrases that users use. Optimise product listings and drive world class SEO.",
  },
]
const statsData = [
  {
    stat: "500+",
    statName: "Categories",
  },
  {
    stat: "10M+",
    statName: "Products",
  },
  {
    stat: "300M+",
    statName: "Reviews",
  },
  {
    stat: "10",
    statName: "Languages",
  },
]
/*
const testimonyData = [
  {
    imgPath: "",
    testimony:
      '"A multi-national electronics company leverages Currents.AI’s commerce engine to build their own understanding of their customers within the larger commerce context. They employ our aggregation, extraction, sampling and verification methodologies to get to a single source of truth for teams across their organization."',
    creds: "",
  },
  {
    imgPath: "",
    testimony:
      '"A leading global brand aggregation company taps Currents.AI to obtain consumer insights of their global consumer base. Currents.AI aggregates customer data across two populations in multiple languages to bring a single source of truth, as well as identify and compare population specific insights that can set them apart from their regional competitors."',
    creds: "",
  },
]
*/

function TheReviewIndex() {
  const { theme } = useTheme()
  const router = useRouter()
  const isDarkMode = theme == "dark"

  return (
    <section>
      <div className="pt-16">
        <TriLandingUnit />
      </div>

      <div className="px-3 py-14 lg:py-32 ">
        <div className="text-center mx-auto max-w-4xl mb-10">
          <Header alignment="center" variant="h4">
            <Header.ShortHeader>Introducing</Header.ShortHeader>
            <Header.MainHeader>TheReviewIndex PRO</Header.MainHeader>
            <Header.Description>
              Convert reviews, ratings and other commerce data to product
              opportunities. Benchmark competition and understand category trends.
            </Header.Description>
          </Header>
        </div>
        {tabData && <ImageTabs isDarkMode={isDarkMode} tabData={tabData} />}
      </div>

      <div className="py-8 lg:py-20 bg-gray-100 dark:bg-opacity-5">
        <LogoGridUnit headingText={"TRUSTED BY"} gridIcons={customerIcons} />
      </div>

      <div className="py-10 lg:py-20 ">
        <FeatureWithSideImage
          image={"/images/demogif.gif"}
          shortHeader={
            <div className=" text-blue-theme uppercase">Transparent AI</div>
          }
          header="Automatic Topics & Sentiment"
          text={
            <div>
              TheReviewIndex PRO automatically categorizes feedback into fine grained
              topics cut by sentiment. No more manual tagging!
              <br />
              <br /> Transparently classified feedback will help you trust the
              insights, and commit to the actions required to grow your business.
            </div>
          }
          actionText={"Get Started"}
          actionLink={"/signup"}
        />

        <FeatureWithSideImage
          imgLeft={true}
          image={"/images/analytics.png"}
          shortHeader={
            <div className=" text-blue-theme uppercase">Adhoc & Real Time</div>
          }
          header="Category Analysis"
          text={
            <div>
              Discover large themes that impact customer experience and drill down
              for data driven actionable insights on root causes with our{" "}
              <b>dynamic analytics engine</b>.<br />
              <br />
              Spend less time trying to generate reports, and more time listening to
              your customers.
            </div>
          }
          actionText={"Get Started"}
          actionLink={"/signup"}
        />
        <div className="py-10 lg:py-20 ">
          <FeatureWithSideImage
            image={"/images/sources.png"}
            shortHeader={<div className=" text-blue-theme uppercase">No Silos</div>}
            header="Multiple Channels"
            text={
              <div>
                Instead of silos, it’s all part of one centralized data warehouse.
                <br />
                <br />
                Connect and analyze different types of customer feedback sources in a
                few clicks, without any development overheads.
              </div>
            }
            actionText={"Get Started"}
            actionLink={"/signup"}
          />
        </div>
      </div>

      <div className="py-10 lg:py-24 bg-gray-100 dark:bg-opacity-5">
        <StatsUnit statsData={statsData} />
      </div>

      <div className="py-12 lg:py-32 ">
        <FeaturesUnit
          headingText={"Automate research across business units"}
          subText={"Tough Questions. Instant Answers."}
          featuresData={featuresData}
        />
      </div>

      <div className="py-8 lg:py-20 bg-gray-100 dark:bg-black">
        <LogoGridUnit
          headingText={"30+ E-COMMERCE CHANNELS"}
          gridIcons={channelIcons}
        />
      </div>
      <div className="w-full bg-muted rounded-lg lg:flex justify-center gap-4 lg:gap-4 xl:gap-20  py-28 lg:py-40">
        <Container8xl>
          <CtaUnit
            heading={
              <div>
                <div>Take Your Brand to the </div>
                <div> Next Level </div>
              </div>
            }
            subHeading="Understand how brands are accelerating business decisions at scale. Learn more about TheReviewIndex."
            primaryActionBtnText="Start Free Trial"
            onPrimaryActionClick={() => router.push("/signup")}
            secondaryActionBtnText="Book a Demo"
            onSecondaryActionClick={() => router.push("/meet")}
            alignment="left"
          />
        </Container8xl>
      </div>
    </section>
  )
}

export default TheReviewIndex
